<template>
  <f7-page class="login-page">
    <template #fixed>
      <CommonNavigationComponent :title="$t.getTranslation('LBL_LOGIN')" type="back" search="false" :hidechat="true" />
    </template>

    <meta name="robots" content="noindex" />

    <!-- <div class="login-header">
      <img src="@/assets/images/pour-wine.png" class="wine-bg" alt="wine pour" />
      <img src="@/assets/images/wine-splash.png" class="wine-splash" alt="wine splash" />

      <h1>{{ $t.getTranslation("LBL_LOGIN_HEADER") }}</h1>
      <img class="logo" src="@/assets/images/logo/logo-name-color.svg" :alt="$t.getTranslation('LBL_PJF_WINE')" />
    </div> -->
    <div class="solo-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/login-bg-full.png') + ')' }">
      <div class=""></div>

      <div class="login-page-container">
        <section class="login-container" style="width:100%;">
          <div style="margin-top: 10px" class="login-message">
            <div class="login-logo"><img src="@/assets/images/logo/logo-name-color.svg" loading="lazy" /></div>
            <h3>{{ $t.getTranslation('LBL_LOGIN_TO_CONTINUE') }}</h3>
            <p>{{ $t.getTranslation('LBL_SIGN_UP_HEADER') }}</p>
          </div>

          <f7-list no-hairlines v-if="$configs.smsEnabled && !isWechat">
            <f7-list-input
              v-model:value="formData.UserName"
              name="UserName"
              :required="validationRules?.UserName?.required"
              :minlength="validationRules?.UserName?.minimumLength"
              :maxlength="validationRules?.UserName?.maximumLength"
              :label="$t.getTranslation('LBL_USERNAME')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              v-model:value="formData.Password"
              name="Password"
              :required="validationRules?.Password?.required"
              :minlength="validationRules?.Password?.minimumLength"
              :maxlength="validationRules?.Password?.maximumLength"
              :label="$t.getTranslation('LBL_PASSWORD')"
              :type="isPasswordShown ? 'text' : 'password'"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              placeholder="********"
              validate
              @keyup.enter="login"
            >
              <template #content-end>
                <f7-link tabindex="-1" class="show-password" @click="togglePassword">
                  <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>

            <f7-link class="forget-btn" style="position: absolute;bottom: 55px;right: 5px;" href="/forgotpassword/">{{ $t.getTranslation('LBL_FORGOT_PASSWORD') }}</f7-link>

            <f7-list-item class="rememberme" :title="$t.getTranslation('LBL_KEEP_ME_SIGN_IN')">
              <template #after>
                <f7-toggle name="IsRememberMe" @change="toggleRememberMe"></f7-toggle>
              </template>
            </f7-list-item>
          </f7-list>

          <f7-button class="login" fill large raised :disabled="isButtonProcessing || isDisableLoginButton" @click="login" v-if="$configs.smsEnabled && !isWechat"> {{ $t.getTranslation('LBL_SIGN_IN') }} </f7-button>
          <p class="btn-between">{{ $t.getTranslation('LBL_OR_LOGIN_WITH') }}</p>
          <f7-button class="login wechat-login" fill large raised v-if="isWechat || !isMobile" @click="loginWechat">
            <font-awesome-icon :icon="['fab', 'weixin']" fixed-width />
            {{ $t.getTranslation('LBL_WECHAT_LOGIN') }}
          </f7-button>

          <div class="register" v-if="$configs.smsEnabled && !isWechat && $configs.isSignupBtn">
            <p style="font-weight: 700; font-size: 16px;">{{ $t.getTranslation('LBL_NOT_A_MEMBER') }}</p>
            <f7-button style="background-image: linear-gradient(#0088FF, #004CFF);" class="login" href="/register/" fill large raised> {{ $t.getTranslation('LBL_SIGN_UP') }} </f7-button>
            <!-- <f7-link href="/forgotpassword/" class="no-ripple">{{ $t.getTranslation('LBL_FORGOT_PASSWORD') }}</f7-link> -->
          </div>

          <FooterComponent />
        </section>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, inject, onMounted, defineAsyncComponent } from 'vue'
import { configs } from '@/utils/configs.js'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'
import { login as authLogin } from '@/logics/auth.js'
import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'

// import CommonNavigationComponent from '@/components/navigations/CommonNavigationComponent.vue'
// import FooterComponent from '@/components/FooterComponent.vue'

const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/CommonNavigationComponent.vue'))
const FooterComponent = defineAsyncComponent(() => import(/* webpackChunkName: "footer" */ /* webpackMode: "lazy" */ '@/components/FooterComponent.vue'))

export default defineComponent({
  name: 'LoginPage',
  components: {
    CommonNavigationComponent,
    FooterComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    const $t = inject('$translation')
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const isPasswordShown = ref(false)
    const isButtonProcessing = ref(false)

    let isWechat = helpers.isInsideWeixin()
    let isMobile = helpers.isMobile()

    const formData = reactive({
      IsRememberMe: 0,
      UserName: '',
      Password: ''
    })

    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    onMounted(() => {
      if (props?.f7route?.query?.group) {
        store.dispatch('register/setData', {
          GroupLinkCode: props.f7route.query.group
        })
      }
    })

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    const togglePassword = () => {
      if (isPasswordShown.value) {
        hidePassword()
      } else {
        showPassword()
      }
    }

    const showPassword = (event) => {
      isPasswordShown.value = true
    }

    const hidePassword = (event) => {
      isPasswordShown.value = false
    }

    const toggleRememberMe = (event) => {
      formData.IsRememberMe = event?.target?.checked ? 1 : 0
    }

    const isDisableLoginButton = computed(() => {
      let isValid = validate(false)

      return !isValid
    })

    const loginWechat = () => {
      let url = `${configs.baseURL}/webhook/wechat/login${isWechat ? '?wechat=1' : '?auth=1'}`
      url += '&lang=' + currentLanguageCode.value
      url += `&host=${window.location.host}`
      url += helpers.isMiniProgram() ? `&is_mini=1` : ``

      const userProductCode = store.getters['user/getData']?.ProductCode

      if (userProductCode) {
        url += `&p_code=${userProductCode}`
      }

      window.location.href = url
    }

    const login = async () => {
      try {
        let isValid = validate(true)

        if (isValid) {
          isButtonProcessing.value = true
          helpers.showLoader()

          let isLogin = await authLogin('/mobile/auth/login', formData)

          if (isLogin) {
            isButtonProcessing.value = false
            helpers.hideLoader()
            setTimeout(function() {
              let userData = computed(() => store.getters['user/getData'])
              if (userData.value?.IsNewUser > 0) {
                props.f7router.navigate({ name: 'homePage' })
              } else {
                props.f7router.navigate({ name: 'shopPage' })
              }
            }, 800)
            return
          }

          throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
        }
      } catch (err) {
        isButtonProcessing.value = false
        helpers.hideLoader()

        helpers.catchError(err, true)
      }
    }

    return {
      formData,
      validationRules,
      isButtonProcessing,
      isPasswordShown,
      showPassword,
      hidePassword,
      isDisableLoginButton,
      login,
      toggleRememberMe,
      loginWechat,
      isWechat,
      isMobile,
      togglePassword
    }
  }
})
</script>

<style scoped>
.login-logo {
  width: 150px;
  margin: 30px 0;
}
.login-logo img {
  width: 100%;
}

.solo-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.login-page-container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.btn-between {
  overflow: hidden;
  text-align: center;
}
.btn-between:before,
.btn-between:after {
  background-color: #000;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
  margin: 0 20px;
}
.btn-between:before {
  right: 0.5em;
  margin-left: -50%;
}
.btn-between:after {
  left: 0.5em;
  margin-right: -50%;
}
</style>
